import styled from 'styled-components'
import { rem, flex } from 'styled-tidy'
import media from 'lib/styles/media'

const ButtonBar = styled.div`
  ${flex('column', 'center', 'center')}
  width: 100%;

  a {
    ${flex('row', 'center', 'center')}
    flex-grow: 1;
    width: 100%;

    span {
      width: 100%;
    }

    svg {
      margin-right: ${rem(8)};
    }

    + a {
      margin: ${rem(8)} 0 0;
    }
  }

  ${media.xsmall`
    flex-direction: row;

    a + a {
      margin: 0 0 0 ${rem(16)};
    }
  `}

  ${media.small`
    justify-content: flex-start;

    a {
      flex-grow: initial;
      width: auto;
    }
  `}
`

export default ButtonBar
