import React, { useState, useEffect } from 'react'
import axios from 'axios'
import styled from 'styled-components'
import { rem, flex } from 'styled-tidy'
import axiosConfig from 'config/axios'
import theme from 'lib/styles/theme'
import media from 'lib/styles/media'
import { Error } from 'components/forms/styles'
import Card from 'components/card'
import Loader from 'components/loader'
import Price from 'components/price'
import Paragraph from 'components/paragraph'

const { colors, fonts } = theme
const { white, vapor, pearl, pink } = colors
const { url: API_URL, headers: API_HEADERS } = axiosConfig.webtask

const SummaryWrap = styled(Card)`
  margin: 0 ${rem(-16)} ${rem(32)};
  width: calc(100% + ${rem(32)});

  ${media.small`
    margin: 0 auto ${rem(32)};
    width: 100%;
  `}
`

const LoaderWrap = styled.div`
  ${flex('row', 'center', 'center')}
  min-height: ${rem(72)};
  padding: ${rem(16)};
  width: 100%;
`

const Items = styled.ul`
  ${flex('column', 'flex-start', 'flex-start')}
  width: 100%;
`

const Item = styled.li`
  ${flex('row', 'center', 'space-between')}
  border-top: ${rem(1)} solid ${pearl};
  list-style: none;
  width: 100%;

  span {
    border-right: ${rem(1)} solid ${pearl};
    font-size: ${rem(13)};
    min-width: ${rem(48)};
    padding: ${rem(8)};
    text-align: right;
  
    :first-child {
      text-align: left;
      flex: 1;
    }

    :last-child {
      border-right: 0;
      min-width: ${rem(80)};
    }
  }

  ${media.small`
    span {
      font-size: ${rem(14)};
      min-width: ${rem(64)};
      padding: ${rem(16)};

      :last-child {
        min-width: ${rem(96)};
      }
    }
  `}
`

const ItemHeader = styled(Item)`
  background: ${vapor};
  border: ${rem(2)} solid ${pearl};
  border-left: none;
  border-right: none;

  span {
    font-family: ${fonts.primary};
  }

  ${media.small`
    span {
      padding: ${rem(8)} ${rem(16)};
    }
  `}
`

const ItemFooter = styled(ItemHeader)`
  background: ${white};
  border-top-width: ${rem(1)};
  border-bottom-width: 0;

  :last-child {
    border-bottom-width: ${rem(1)};
  }

  span {
    :first-child {
      text-align: right;
    }

    :last-child {
      font-family: inherit;
    }
  }
`

const OrderSummary = ({ oid }) => {
  const [order, setOrder] = useState(null)
  const [error, setError] = useState(oid ? '' : 'No order ID given')
  const [isFetchingOrder, setIsFetchingOrder] = useState(true)

  const getSubtotal = () =>
    order.items.reduce((total, item) => ({
      amount: total.amount + (item.type === 'sku' ? item.amount : 0),
    })).amount

  useEffect(() => {
    let didCancel = false
    const fetchOrder = async () => {
      try {
        const { data } = await axios.post(
          `${API_URL}order-data`,
          { oid },
          { headers: API_HEADERS },
        )
        if (didCancel) return
        if (data.success && data.order) {
          if (error) setError('')
          setOrder(data.order)
        } else {
          setError(data.error || 'An error occurred fetching your order.')
        }
        setIsFetchingOrder(false)
      } catch (err) {
        if (didCancel) return
        setError(err.message)
        setIsFetchingOrder(false)
      }
    }
    if (!order && !error) fetchOrder()
    return () => (didCancel = true)
  }, [order, error, setIsFetchingOrder, setOrder, setError])

  return (
    <>
      <Paragraph>
        Order {oid ? `#${oid.split('_').pop()}` : 'Not Found'}
      </Paragraph>
      {oid && isFetchingOrder && (
        <LoaderWrap>
          <Loader size={72} fill={pink} />
        </LoaderWrap>
      )}
      <SummaryWrap>
        {!isFetchingOrder && order && (
          <Items>
            <ItemHeader>
              <span>Item</span>
              <span>Qty</span>
              <span>Price</span>
            </ItemHeader>
            {order.items.map((item, key) => {
              if (item.type !== 'sku') return ''
              const {
                description,
                quantity,
                amount,
                attributes: { type, width: w, height: h },
              } = item
              const name = `${description}${isPrint ? ' · Print' : ''}`
              const isPrint = type === 'print'
              return (
                <Item key={`order-summary-item-${key}`}>
                  <span>
                    {name} · (${w} × ${h})
                  </span>
                  <span>{quantity}</span>
                  <Price cents={amount} />
                </Item>
              )
            })}
            <ItemFooter>
              <span>Subtotal</span>
              <Price cents={getSubtotal()} />
            </ItemFooter>
            {order.items.map((item, key) => {
              if (item.type === 'sku') return ''
              return (
                <ItemFooter key={`order-summary-item-shipping-${key}`}>
                  <span>{item.description}</span>
                  <Price cents={item.amount} />
                </ItemFooter>
              )
            })}
            <ItemFooter>
              <span>Total</span>
              <strong>
                <Price cents={order.amount} />
              </strong>
            </ItemFooter>
          </Items>
        )}
        {error ? <Error>{error}</Error> : ''}
      </SummaryWrap>
    </>
  )
}

export default OrderSummary
