import styled from 'styled-components'
import { rem, flex } from 'styled-tidy'
import theme from 'lib/styles/theme'

const { white, shadow } = theme.colors

export const Card = styled.div`
  ${flex('column', 'center', 'space-between')}
  background: ${white};
  box-shadow: 0 ${rem(2)} ${rem(4)} ${shadow};
  min-width: ${rem(128)};
  position: relative;
  text-align: left;
  user-select: none;
`

export default Card
