import styled from 'styled-components'
import {
  rem,
  flex,
  position,
  value,
  is,
  swap,
  transparentize,
} from 'styled-tidy'
import BaseTitle from 'components/title'
import theme from 'lib/styles/theme'
import media from 'lib/styles/media'
import Button from 'components/button'

const { colors, fonts, speeds } = theme
const { white, vapor, silver, iron, tar, shadow, blue, yellow } = colors
const autoFillColor = transparentize(yellow, 0.8)

export const Form = styled.form`
  ${flex('column', 'flex-start', 'flex-start')}
  margin: 0 0 ${rem(64)};
  max-width: ${rem(576)};
  width: 100%;
`

export const Title = styled(BaseTitle).attrs({
  is: 'h2',
})`
  margin: ${rem(24)} 0 ${rem(8)} !important;
`

export const BillingTitle = styled(BaseTitle).attrs({
  is: 'h2',
})`
  &&& {
    ${flex('column', 'center', 'center')}
    margin: ${rem(24)} 0 ${rem(8)};
  }

  > div {
    justify-content: center !important;
    margin-top: ${rem(8)};

    > span {
      white-space: nowrap;
    }
  }

  ${media.small`
    &&& {
      align-items: flex-end;
      flex-direction: row;
      justify-content: space-between;
      margin: ${rem(16)} 0 ${rem(8)};
    }

    > div {
      margin-top: 0;
    }
  `}
`

export const Input = styled.input`
  background: ${white};
  border: ${rem(1)} solid ${vapor};
  box-shadow: 0 ${rem(2)} ${rem(1)} ${shadow};
  font-size: ${rem(14)};
  margin: ${rem(8)} 0 0;
  max-width: 100%;
  padding: ${rem(16)};
  width: 100%;

  :-webkit-autofill {
    &,
    :hover,
    :focus,
    :active {
      -webkit-box-shadow: inset 0 0 0 ${rem(48)} ${autoFillColor},
        0 ${rem(2)} ${rem(1)} ${shadow};
      -webkit-transition: color 9999s ease-out, background-color 9999s ease-out;
      -webkit-transition-delay: 9999s;
    }
  }
`

export const NakedInputWrap = styled.div`
  background: ${white};
  border: ${rem(1)} solid ${vapor};
  bottom: -200%;
  box-shadow: 0 ${rem(2)} ${rem(1)} ${shadow};
  margin: ${rem(8)} 0 0;
  max-height: ${value('height')};
  overflow: hidden;
  padding: ${rem(8)};
  position: relative;
  width: 100%;

  ${is('field', 'select')`
    :after {
      ${position('absolute', '50%', 0, 'auto', 'auto')}
      background-image:
        url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHBvbHlnb24gY2xhc3M9ImNscy0yIiBwb2ludHM9IjEuNDEgNC42NyAyLjQ4IDMuMTggMy41NCA0LjY3IDEuNDEgNC42NyIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIzLjU0IDUuMzMgMi40OCA2LjgyIDEuNDEgNS4zMyAzLjU0IDUuMzMiLz48L3N2Zz4=);
      background-repeat: no-repeat;
      content: '';
      height: ${rem(32)};
      pointer-events: none;
      transform: translateY(-50%);
      width: ${rem(24)};
    }
  `}

  select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background: ${white} no-repeat 100% 50%;
    border: none;
    color: ${swap('hasValue', tar, silver)};
    cursor: pointer;
    font-size: ${rem(14)};
    padding: ${rem(8)};
    width: 100%;

    :-webkit-autofill {
      &,
      :hover,
      :focus,
      :active {
        -webkit-text-fill-color: ${tar};
        -webkit-box-shadow: inset 0 0 0 ${rem(48)} ${autoFillColor},
          0 0 0 ${rem(8)} ${autoFillColor};
        -webkit-transition: color 9999s ease-out,
          background-color 9999s ease-out;
        -webkit-transition-delay: 9999s;
      }
    }
  }
`

export const Error = styled.mark`
  background: ${blue};
  box-shadow: inset 0 0 0 ${rem(1)} rgba(0, 0, 0, 0.07);
  color: ${tar};
  display: block;
  font-size: ${rem(13)};
  font-weight: 600;
  line-height: ${rem(14)};
  padding: ${rem(12)};
  text-align: left;
  user-select: none;
  width: 100%;
`

export const RowSplit = styled.div`
  ${flex('row', 'flex-start', 'stretch')}
  width: 100%;

  button {
    height: ${rem(52)};
    margin: ${rem(8)} 0 0;
  }
  > *:nth-child(1) {
    flex: ${({ split }) => split[0]};
  }
  > *:nth-child(2) {
    width: ${({ split }) => (split.length > 1 ? `${split[1]}%` : 'inital')};
  }
  > *:nth-child(3) {
    width: ${({ split }) => (split.length > 2 ? `${split[2]}%` : 'inital')};
  }
`

export const RowSplitItem = styled.div`
  ${flex('column', 'flex-start', 'flex-start')}
`

export const SubmitButton = styled(Button).attrs({
  type: 'submit',
  kind: 'primary',
})`
  margin: ${rem(16)} 0 0;
  width: 100%;

  ${media.small`
    width: auto;
  `}
`

export const StripeElementWrap = styled.div`
  padding: ${rem(8)};
  width: 100%;

  div,
  iframe {
    max-height: ${rem(16)};
  }
`

export const ToggleWrap = styled.div`
  ${flex('row', 'center', 'flex-start')}
  color: ${iron};
  font-size: ${rem(14)};
  justify-self: flex-end;
  letter-spacing: initial;
  text-transform: initial;

  > div {
    margin-left: ${rem(8)};
  }
`
