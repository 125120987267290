import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { rem, flex } from 'styled-tidy'
import theme from 'lib/styles/theme'
import media from 'lib/styles/media'
import siteConfig from 'config/site'
import { getParamFromQueryString } from 'lib/common/helpers'
import Layout from 'layouts/default'
import Wrap from 'components/wrap'
import Title from 'components/title'
import OrderSummary from 'components/order-summary'
import Blockquote from 'components/blockquote'
import BounceLetters from 'components/bounce-letters'
import Button from 'components/button'
import ButtonBar from 'components/button-bar'
import Instagram from 'components/icons/instagram'
import knucks from 'images/knucks.jpg'
import whatNextBackground from 'images/what-next-background.jpg'

const { colors, speeds } = theme
const { white, iron, ember } = colors

const StyledWrap = styled(Wrap)`
  align-items: flex-start !important;
  flex-direction: column;
  justify-content: flex-start !important;

  h1 {
    font-size: ${rem(32)};
  }

  ${media.medium`
    max-width: ${rem(640)} !important;
  `}
`

const Splash = styled.div.attrs({
  style: {
    backgroundImage: `url(${knucks})`,
  },
})`
  background: 50% 50% no-repeat #fec5d0;
  background-size: 120% auto;
  min-height: ${rem(128)};
  position: relative;
  width: 100%;

  :before {
    content: '';
    display: block;
    padding-bottom: 33.33%;
  }

  ${media.small`
    animation: splashOpen ${speeds.slow}ms ease-in-out forwards;
    min-height: 0;

    :before {
      display: none;
    }
  `}

  ${media.medium`
    background-size: auto 240%;
  `}
`

const WhatNext = styled.div`
  ${flex('column', 'center', 'center')}
  background: url(${whatNextBackground}) 50% 50% no-repeat ${ember};
  background-size: cover;
  overflow: hidden;
  padding: ${rem(32)} ${rem(16)} ${rem(64)};
  position: relative;
  width: 100%;

  h2 {
    color: ${white};
    border-color: ${iron};
  }
`

const ThanksPage = () => {
  const href = global.window ? global.location.href : ''
  const oid = getParamFromQueryString('oid', href)

  return (
    <Layout path="/thanks">
      <Splash />
      <StyledWrap>
        <Title is="h1">
          <BounceLetters>Success</BounceLetters>
        </Title>
        <Blockquote>
          You rock. We've sent you an order confirmation email.
          {oid && " We'll send you another when your order ships out."}
        </Blockquote>
        <Title is="h2">Order Summary</Title>
        <OrderSummary oid={oid} />
        <Blockquote>Thank you for supporting us.</Blockquote>
      </StyledWrap>
      <WhatNext>
        <StyledWrap>
          <Title is="h2">What Next?</Title>
          <ButtonBar>
            <Button is="a" href={siteConfig.social.instagram.url}>
              <Instagram size={18} color="inherit" />
              Spread the Love
            </Button>
            <Link to="/shop">
              <Button is="span">Keep Browsing &rarr;</Button>
            </Link>
          </ButtonBar>
        </StyledWrap>
      </WhatNext>
    </Layout>
  )
}

export default ThanksPage
