import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { rem, position } from 'styled-tidy'

export const BounceLettersWrap = styled.div`
  display: inline-block;

  &,
  span {
    color: inherit;
    font-family: inherit;
    font-size: 100%;
    letter-spacing: 0;
    padding: 0 ${rem(1)};
    text-align: center;
  }
`

const LetterWrap = styled.span`
  position: relative;

  ${[...Array(24).keys()].map(
    n => css`
      :nth-child(${n + 2}) span {
        animation-delay: ${(n + 1) * 50}ms;
      }
    `,
  )}
`

const Letter = styled.span`
  visibility: hidden;
`

const BounceLetter = styled.span`
  animation: bounceLetter 800ms forwards;
  opacity: 0;
  ${position('absolute', 0)}
`

const BounceLetters = ({ children }) => (
  <BounceLettersWrap>
    {children.split('').map((letter, key) => (
      <LetterWrap key={`bounce-letter-${key}-${letter}`}>
        <Letter>{letter}</Letter>
        <BounceLetter>{letter}</BounceLetter>
      </LetterWrap>
    ))}
  </BounceLettersWrap>
)
BounceLetters.propTypes = {
  children: PropTypes.string,
}

export default BounceLetters
