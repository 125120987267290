import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { rem, position } from 'styled-tidy'
import theme from 'lib/styles/theme'

const { black, tar, pearl, shadow } = theme.colors

const StyledBlockquote = styled.blockquote`
  background: ${({ bg }) => bg || tar};
  border-radius: ${rem(8)};
  box-shadow: 0 ${rem(2)} ${rem(4)} ${shadow};
  color: ${({ color }) => color || pearl};
  font-size: ${rem(14)};
  font-weight: 600;
  margin-bottom: ${rem(16)};
  padding: ${rem(16)};
  position: relative;
  text-align: left;

  :before {
    border-top: ${rem(8)} solid ${shadow};
    border-right: ${rem(8)} solid transparent;
    content: '';
    filter: blur(${rem(1)});
    margin: ${rem(1)} 0 0;
    ${position('absolute', '100%', 'auto', 'auto', 16)}
  }

  :after {
    border-top: ${rem(8)} solid ${({ bg }) => bg || black};
    border-right: ${rem(8)} solid transparent;
    content: '';
    ${position('absolute', '100%', 'auto', 'auto', 16)}
  }
`

const Blockquote = ({ children, ...rest }) => {
  return <StyledBlockquote {...rest}>{children}</StyledBlockquote>
}
Blockquote.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

export default Blockquote
